import logo from './logo.svg';
import Seasons from "./Seasons";
import Matches from "./Matches";
import React, {useState, useEffect} from 'react';
import Api from "./Api";

function App() {

    const [selectedSeason, setSelectedSeason] = useState();
    const [matches, setMatches] = useState({});

    useEffect(() => {
        Api.get('matches')
            .then(response => response.data)
            .then(data => setMatches(data['competitions']));
    }, []);

    const handleChangeSeason = (e) => {
        const seasonIdentifier = e.target.value;
        Api.get(`matches/by-period/${seasonIdentifier}`)
            .then(response => response['data'])
            .then(data => {
                setSelectedSeason(seasonIdentifier);
                setMatches(data['competitions']);
            })
    };

    return (
        <div>
            <header className="header">
                <img src={logo} className="logo" alt="logo" />
                <h1>ReplayWorthy</h1>
            </header>
            <Seasons selectedSeason={selectedSeason} handleChange={handleChangeSeason}/>
            <Matches matches={matches} />
        </div>
    );
}

export default App;
