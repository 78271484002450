import Questions from "./Questions";
import React, {useEffect, useState} from "react";
import nicknames from './resources/nicknames.json';

function Match({match: inMatch}) {

    const [showQuestions, setShowQuestions] = useState(false);

    const [match, setMatch] = useState({
        id: "",
        utcDate: "",
        matchday: "",
        homeTeam: {
            name: "",
            logo: "",
        },
        awayTeam: {
            name: "",
            logo: "",
        },
        score: {
            spliceDelimiter: '',
            winner: null,
            duration: 'REGULAR',
            fullTime: {
                homeTeam: null,
                awayTeam: null,
            },
            halfTime: {
                homeTeam: null,
                awayTeam: null,
            },
        }
    });

    useEffect(() => {
        setMatch(inMatch);
    }, [inMatch]);

    const toggleShowQuestions = () => {
        setShowQuestions(prevState => !prevState);
    }

    const months = [
        'januar', 'februar', 'mars', 'april', 'mai', 'juni',
        'juli', 'august', 'september', 'oktober', 'november', 'desember'
    ];

    const formatDate = (date) => {
        const rawDate = new Date(date);

        return rawDate.getDate() + ". " + months[rawDate.getMonth()] + " " + rawDate.getFullYear();
    };

    const teamLogoExt = (teamId) => `https://crests.football-data.org/${teamId}.svg`;
    //const teamLogo = (teamName) => teamName.replace(/\s/g, "-").toLowerCase() + '.png';

    if (match.id === "") {
        return "";
    }

    return (
        <div className="match">
            <div className="match-info" onClick={toggleShowQuestions}>
                <div className="team-home">
                    <div className="top-text">
                        {formatDate(match.utcDate)}
                    </div>
                    <div className="team-info">
                        <div className="team-info-name">
                            {nicknames[match.homeTeam.name] || match.homeTeam.name}
                        </div>
                        <div className="team-info-logo">
                            <img src={teamLogoExt(match.homeTeam.id)} alt="" className="team-logo" />
                        </div>
                    </div>
                </div>
                <div className="team-center" />
                <div className="team-away">
                    <div className="top-text">
                        Runde {match.matchday}
                    </div>
                    <div className="team-info team-info-away">
                        <div className="team-info-logo">
                            <img src={teamLogoExt(match.awayTeam.id)} alt="" className="team-logo" />
                        </div>
                        <div className="team-info-name">
                            {nicknames[match.awayTeam.name] || match.awayTeam.name}
                        </div>
                    </div>
                </div>
            </div>
            { showQuestions && <Questions match={match}/> }
        </div>
    )
}

export default Match;
