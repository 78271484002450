import React, {useState, useEffect} from 'react';
import Api from "./Api";

function Seasons({selectedSeason, handleChange}) {

    const [seasons, setSeasons] = useState([]);

    useEffect(() => {
        Api.get('seasons')
            .then(response => response.data)
            .then(data => setSeasons(data['seasons']));
    }, []);

    const renderedOptions = seasons.map(season => (
        <option key={season.id} value={`${season.startDate}_${season.endDate}`}>{season.name}</option>
    ));

    return (
        <div className="text-center mb-2">
            <h5>Select season:</h5>
            <select name="selectedSeason" value={selectedSeason} onChange={handleChange}>
                {renderedOptions}
            </select>
        </div>
    );
}

export default Seasons;
