const didCityWin = (match) => {
    const isHome = match.homeTeam.name === 'Manchester City FC';
    if (isHome) {
        return match.score.fullTime.homeTeam > match.score.fullTime.awayTeam;
    }

    return match.score.fullTime.homeTeam < match.score.fullTime.awayTeam;
}

const didCityLose = (match) => {
    const isHome = match.homeTeam.name === 'Manchester City FC';
    if (isHome) {
        return match.score.fullTime.homeTeam < match.score.fullTime.awayTeam;
    }

    return match.score.fullTime.homeTeam > match.score.fullTime.awayTeam;
}

const didMatchEndDraw = (match) => {
    return match.score.fullTime.homeTeam === match.score.fullTime.awayTeam;
}

const wasMatchScoreless = (match) => {
    return match.score.fullTime.homeTeam === 0 && match.score.fullTime.awayTeam === 0
}

const fullTimeResult = (match) => {
    return match.score.fullTime.homeTeam + match.score.spliceDelimiter + match.score.fullTime.awayTeam;
}

const wonByAtLeast = (match, goals) => {

    if (match.homeTeam.name === 'Manchester City FC') {
        return (match.score.fullTime.homeTeam - goals) >= match.score.fullTime.awayTeam;
    }

    return (match.score.fullTime.awayTeam - goals) >= match.score.fullTime.homeTeam;
}

const lostByAtLeast = (match, goals) => {

    if (match.homeTeam.name === 'Manchester City FC') {
        return match.score.fullTime.homeTeam <= (match.score.fullTime.awayTeam - goals);
    }

    return match.score.fullTime.awayTeam <= (match.score.fullTime.homeTeam - goals);
}

const blowout = (match, goals) => {
    return Math.abs(match.score.fullTime.homeTeam - match.score.fullTime.awayTeam) >= goals;
}

export {
    didCityWin,
    didCityLose,
    didMatchEndDraw,
    wasMatchScoreless,
    fullTimeResult,
    wonByAtLeast,
    lostByAtLeast,
    blowout,
}
