import React, {useState} from "react";

function Question({question, answer, boolean}) {

    if (boolean === undefined) {
        boolean = true;
    }
    const [showAnswer, setShowAnswer] = useState(false);

    const toggleShowAnswer = () => {
        setShowAnswer(prevState => !prevState);
    }

    const answerClass = () => {
        if (boolean) {
            return answer ? 'answer-yes' : 'answer-no';
        }

        return "";
    }
    const answerText = () => {
        if (boolean) {
            return answer ? 'Yes' : 'No';
        }
        return answer;
    }

    return (
        <div className="question" onClick={toggleShowAnswer}>
            <span>{question} </span>
            {showAnswer &&
                <span className={answerClass()}>{answerText()}</span>
            }
        </div>
    );
}

export default Question;