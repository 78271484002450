import React from "react";
import Question from "./Question";
import {
    didCityWin,
    didMatchEndDraw,
    didCityLose,
    wasMatchScoreless,
    fullTimeResult,
    wonByAtLeast,
    lostByAtLeast,
    blowout,
} from "./QuestionsResource";
import {useState} from "react";

function Questions({match}) {

    const [showCategory, setShowCategory] = useState({
        margin: false,
        blowout: false,
    });

    const toggleCategory = (category) => {
        setShowCategory(prevState => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };

    return (
        <div className="questions-container">
            <Question question="Did City win?" answer={didCityWin(match)} />
            <Question question="Was match a draw?" answer={didMatchEndDraw(match)} />
            <Question question="Did City lose?" answer={didCityLose(match)} />
            <Question question="Was match scoreless?" answer={wasMatchScoreless(match)} />
            <Question question="Full time result?" answer={fullTimeResult(match)} boolean={false} />
            <div className="question">
                <div className="question-category-title" onClick={() => toggleCategory('margin')}>Margin:</div>
                <div className="question-category-body" style={{display: showCategory.margin ? 'block' : 'none'}}>
                    <Question question="City won by 1+ goals?" answer={wonByAtLeast(match, 1)} />
                    <Question question="City won by 2+ goals?" answer={wonByAtLeast(match, 2)} />
                    <Question question="City won by 3+ goals?" answer={wonByAtLeast(match, 3)} />
                    <Question question="City lost by 1+ goals?" answer={lostByAtLeast(match, 1)} />
                    <Question question="City lost by 2+ goals?" answer={lostByAtLeast(match, 2)} />
                    <Question question="City lost by 3+ goals?" answer={lostByAtLeast(match, 3)} />
                </div>
            </div>
            <div className="question">
                <div className="question-category-title" onClick={() => toggleCategory('blowout')}>Blowout:</div>
                <div className="question-category-body" style={{display: showCategory.blowout ? 'block' : 'none'}}>
                    <Question question="2+ goals?" answer={blowout(match, 2)} />
                    <Question question="3+ goals?" answer={blowout(match, 3)} />
                    <Question question="4+ goals?" answer={blowout(match, 4)} />
                </div>
            </div>
        </div>
    )
}

export default Questions;