import React from "react";
import Match from "./Match";
import {useState} from "react";
import tournaments from './resources/tournaments.json';

function Matches({matches}) {

    const [activeTournament, setActiveTournament] = useState('PL');

    const changeTournament = (tournament) => {
        setActiveTournament(tournament);
    };

    const renderedTournaments = tournaments.map(tournament => (
        <div key={tournament.alias} className={'tournaments-tournament ' + (activeTournament === tournament.alias ? 'active' : '')} onClick={() => changeTournament(tournament.alias)}>
            <img src={'./competitions/' + tournament.logo} alt="" />
        </div>
    ));

    let renderedMatches = (
        <h4 className={"text-center"}>No matches found</h4>
    );
    if (matches.hasOwnProperty(activeTournament)) {
        renderedMatches = matches[activeTournament].map(match => {
            return <Match match={match} key={match.id} />;
        });
    }

    return (
        <>
            <div className="tournaments">
                {renderedTournaments}
            </div>
            <div className="matches">
                {renderedMatches}
            </div>
        </>
    )
}

export default Matches
